import React, { useEffect, useState } from "react"
import { Router } from "@reach/router"
import Statistics from "./metrics/statistics"
import ErrorPage from "./404"
import { SystemAdminRoute, SuperAdminRoute } from "../components/admin-route"
// import ProjectDetailPage from "./metrics/project-detail"
import UsersDetail from "./metrics/users-detail"
import ParentOrgDetail from "./metrics/parent-organization-detail"
import { useAuth0 } from "@auth0/auth0-react"
import { Loading } from "../components/loading"
import { Error } from "../components/error"
import { fetch_get } from "../utils/fetch"

const Metrics = () => {
  const { isLoading, error } = useAuth0()

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {error && <Error message={error.message} />}
      <Router>
        <SystemAdminRoute path="/metrics" component={Statistics} />
        {/* <SystemAdminRoute
          path="/metrics/projects/:parentOrganization/:id"
          component={ProjectDetailPage}
        /> */}
        {/* <SystemAdminRoute
          path="/:parentOrganization/projects/:id/metrics"
          component={ProjectDetailPage}
        /> */}
        <SystemAdminRoute path="/metrics/users/:id" component={UsersDetail} />
        <SystemAdminRoute
          path="/metrics/parentorganizations/:parentOrg"
          component={ParentOrgDetail}
        />
        <ErrorPage default />
      </Router>
    </>
  )
}

export default Metrics
